@import "./Palette.module.scss";
@import "./Variables.module.scss";
@import "./MediaQueries.module.scss";
@import "./Font.module.scss";

@mixin anchorColor($color, $hover: $color) {
  color: $color;
  transition: color 250ms ease-in-out;

  &:hover,
  &:focus,
  &:active {
    color: $hover;
  }
}

@mixin lineClamp($lines) {
  display: -webkit-box;
  -webkit-line-clamp: $lines;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

@mixin textEllipsis($max-width: 100%) {
  max-width: $max-width;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@mixin responsivePadding() {
  padding: $normalSpacing;

  @include medium() {
    padding: $normalSpacing;
  }

  @include largeUp() {
    padding: $mediumSpacing;
  }
}

@mixin responsivefont() {
  font-size: $smallfontSize;

  @include mediumUp() {
    font-size: $basefontSize;
  }
}

@mixin accelerate($property) {
  will-change: $property;
  transform: translateZ(0);
  backface-visibility: hidden;
  perspective: 1000px;
}

@mixin tapHighlightColor($color: transparent) {
  -webkit-tap-highlight-color: $color;
}

@mixin defaultBoxShadow($level: 1) {
  @if $level == 2 {
    box-shadow: 2px 2px 4px 0 $pqMediumGray;
  } @else {
    box-shadow: 1px 2px 1px $pqLightGray;
  }
}

@mixin advPlaceholder() {
  content: "AD";
  position: absolute;
  top: calc(50% - 15px);
  left: calc(50% - 15px);
  z-index: 1;
  border: $baseBorderAdv;
  border-radius: $baseBorderRadiusDefault;
  padding: calc($smallSpacing / 2) $smallSpacing;
  color: $pqLightGray;
  font-weight: 700;
}
