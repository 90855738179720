$pqOrange: #ef7623;
$pqGreen: #8bc34a;
$pqBlue: #42a5f5;
$pqYellow: #f9a825;
$pqBlack: #4a4a4a;
$pqRed: #e65100;

$pqPrimary: $pqGreen;
$pqActionColor: #1565c0;

$pqBgPrimaryColor: whitesmoke;
$pqBgSecondaryColor: white;
$pqBgMutedColor: #eee;
$pqBgBlockColor: #006379;

$pqLightestGray: #f5f5f5;
$pqLighterGray: #dedede;
$pqLightGray: #ddd;
$pqMediumGray: #999;
$pqMediumDarkGray: #666;
$pqDarkGray: #333;

$pqLightBlue: #c1e7ea4d;
$pqLightGreen: #edf6e3;

$pqTextColor: $pqDarkGray;
$pqTextMuted: #888;

$pqBaseBorderColor: $pqBgPrimaryColor;

$pqBaseShadow: 0 0 16px 0 $pqMediumDarkGray;
$pqLightShadow: 0px 3px 3px 0px $pqLightGray;

// Socials
$facebook: #3b5999;
$twitter: #55acee;
$google-plus: #dd4b39;
$pinterest: #bd081c;
$instagram: #e4405f;
$google: #4285f4;
$whatsapp: #25d366;

:export {
  pqOrange: $pqOrange;
  pqGreen: $pqGreen;
  pqBlue: $pqBlue;
  pqYellow: $pqYellow;
  pqBlack: $pqBlack;
  pqRed: $pqRed;
  pqLightGray: $pqLightGray;
}
