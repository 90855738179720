@import "@/commons/ui/styles/Variables.module.scss";
@import "@/commons/ui/styles/MediaQueries.module.scss";
@import "@/commons/ui/styles/Mixin.module.scss";

.mainAdv {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: $baseSpacing;
    overflow: visible;
    position: relative;
    width: 100%;

    @include small {
      min-height: 50px;
    }

    @include medium {
      min-height: 90px;
    }

    @include largeUp {
      min-height: 250px;
    }

    & > div {
      position: relative;
      z-index: 2;
    }

    &:after {
      @include advPlaceholder();
    }
  }

  .serpAdv {
    position: relative;
    min-height: 90px;
    width: 100%;
    margin: $baseSpacing 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @include mediumDown() {
      min-height: 280px;
    }

    & > div {
      position: relative;
      z-index: 2;
    }

    &:after {
      @include advPlaceholder();
    }
  }
