$mobileMinWidth: 320px;
$tabletMinWidth: 768px;
$desktopMinWidth: 1300px;
$lgDesktopMinWidth: 1800px;
$xlDesktopMinWidth: 2400px;

@mixin small($media: all) {
  @media #{$media} and (max-width: calc($tabletMinWidth - 1px)) {
    @content;
  }
}

@mixin smallLandscape($media: all) {
  @media #{$media} and (max-width: calc($tabletMinWidth - 1px)) and (orientation: landscape) {
    @content;
  }
}

@mixin smallPortrait($media: all) {
  @media #{$media} and (max-width: calc($tabletMinWidth - 1px)) and (orientation: portrait) {
    @content;
  }
}

@mixin medium($media: all) {
  @media #{$media} and (min-width: $tabletMinWidth) and (max-width: calc($desktopMinWidth - 1px)) {
    @content;
  }
}

@mixin mediumUp($media: all) {
  @media #{$media} and (min-width: $tabletMinWidth) {
    @content;
  }
}

@mixin mediumDown($media: all) {
  @media #{$media} and (max-width: calc($desktopMinWidth - 1px)) {
    @content;
  }
}

@mixin mediumPortrait($media: all) {
  @media #{$media} and (min-width: $tabletMinWidth) and (max-width: calc($desktopMinWidth - 1px)) and (orientation: portrait) {
    @content;
  }
}

@mixin mediumLandscape($media: all) {
  @media #{$media} and (min-width: $tabletMinWidth) and (max-width: calc($desktopMinWidth - 1px)) and (orientation: landscape) {
    @content;
  }
}

@mixin large($media: all) {
  @media #{$media} and (min-width: $desktopMinWidth) and (max-width: calc($lgDesktopMinWidth - 1px)) {
    @content;
  }
}

@mixin largeUp($media: all) {
  @media #{$media} and (min-width: $desktopMinWidth) {
    @content;
  }
}

@mixin largeDown($media: all) {
  @media #{$media} and (max-width: calc($lgDesktopMinWidth - 1px)) {
    @content;
  }
}

@mixin xLarge($media: all) {
  @media #{$media} and (min-width: $xlDesktopMinWidth) {
    @content;
  }
}
